
.thnx-detail-section{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 6.46%, rgba(255, 255, 255, 0.06) 100%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    border-radius: 2rem;
    text-align: center;
    padding: 2rem;
}

.thanks-box{
    transform: translate(0 , 25%);
}

.thnx-mainBackground{
    background-image: url(../assets/images/nft-home-bg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
    padding-bottom: 15em;
}

.thnx-logo-box img{
    height: 2em !important;
    object-fit: contain !important;
    width: 100% !important;
}

.thanks-box img{
    height: 20em;
    width: 100%;
    object-fit: contain;
}

.thnx-big-content img{
    height: 4em !important;
    width: 100% !important;
    object-fit: contain !important;
    margin: 2rem 0;
}

.thnx-other-content p{
   margin-bottom: 20px !important;
   font-style: Montserrat !important;
   color: #ffffff !important   ;
}

.thnx-button-part{
    display: flex;
    justify-content: center;
}

.thnx-button-part button{
    margin: 0 10px;
}

.thnx-blue{
    color: #4BC8EF !important;
}

.thanks-box .link-button {
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%);
    border: none;
    color: #fff;
    padding: 8px 12px;
    border-radius: 7px;
    margin-top: 1rem;
    display: flex;
    border: 2px solid linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%);
    justify-content: center;
    text-decoration: none !important;
}

.thanks-box svg{
    height: 1.2rem;
    width: 1.2rem;
    margin: 0 5px;
}

.thnx-btn{
    display: flex;
    justify-content: center;
}

@media(max-width:767px){
    .thanks-page-image{
        display: none !important;
    }
    .thnx-other-content p {
        font-size: 14px;
    }
    .thnx-big-content img{
        height: 3em !important;
    }
    .thnx-logo-box img {
        height: 1.3em !important;
    }
}

@media(min-width:768px) and (max-width:1024px){
    .thanks-box img{
        height: 15em !important;
    }
    .thnx-logo-box img{
        height: 1em !important;
    }
    .thnx-big-content img{
        height: 3em !important;
    }
    .thnx-other-content p{
        font-size: 12px !important;
    }
}