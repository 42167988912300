/* ================================== 
 Footer section css  
=============================== */
.footer {
  padding: 3rem 0 1.5rem;
  /* box-shadow: 0px -1px 2px rgb(20, 20, 20); */
  background-color: var(--body-main-bg);
  z-index: 700;
}

.footer .footer-media img {
  width: auto;
  height: 2rem;
}

.footer .footer-media .media-list {
  margin-top: 30px;
}

.footer .footer-media .media-list ul {
  display: flex;
  gap: 20px;
}

.footer .footer-media .media-list ul li .footer-icon {
  background-color: var(--text-tersury);
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
}

.footer .footer-media .media-list ul li a {
  text-decoration: none;
  color: inherit;
}

.footer .footer-media .media-list ul li .footer-icon:hover {
  background-color: var(--text-primary);
}

.footer .Know-more .title,
.footer .footer-company .title {
  font-size: 1.5rem;
}

.footer .Know-more,
.footer .footer-company {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.footer .Know-more ul,
.footer .footer-company ul {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin-top: 10px;
  color: var(--text-tersury);
}

.footer .Know-more ul li a,
.footer .footer-company ul li a {
  color: #ffff;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.footer .Know-more ul li a:hover,
.footer .footer-company ul li a:hover {
  color: var(--text-primary);
}

.footer .copyright-mobile {
  display: none;
}

.footer .copyright-destop {
  font-size: 1.1rem;
  display: inline-block;
}

/* media queries less then 992px */
@media (max-width: 992px) {
  .footer .footer-media {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .footer .footer-data {
    margin-top: 25px;
  }

  .footer .copyright-mobile {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .footer .copyright-destop {
    display: none;
  }
  .web{
    display: none !important;
  }
  .mob{
    display: block !important;
    margin-top: 1em;
  }
}

.web{
  display: block;
}

.mob{
  display: none;
}