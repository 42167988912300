/* =================================
buttons css
==================================== */
.btn_wrapper {
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}

.PrimaryBtn {
  background: var(--btn-primary-bg);
  color: var(--text-primary);
}

.PrimaryBtn:hover,
.PrimaryBtn:active {
  background: var(--text-primary);
  color: var(--helper-main);
}

.SecondaryBtn {
  background: var(--text-primary);
  color: var(--helper-main);
  border: 1px solid var(--text-primary);
}

.SecondaryBtn:hover,
.SecondaryBtn:active {
  background: transparent;
  color: var(--text-primary);
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
}

/* =================================
Card css
==================================== */

.primaryCard,
.scecondaryCard {
  position: relative;
  background-color: var(--card-primary-bg);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.primaryCard::before,
.scecondaryCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: transparent !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.primaryCard::after,
.scecondaryCard::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(30px);
  -webkit-filter: blur(30px);
}

.primaryCard .border-line,
.scecondaryCard .border-line {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 20px;
  background: transparent !important;
  z-index: 2;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.scecondaryCard:hover {
  cursor: pointer;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.teasuryCard {
  /* background-color: var(--btn-primary-bg); */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background-image: radial-gradient(farthest-side at bottom left, #271d3b, transparent 900px),
    radial-gradient(farthest-corner at bottom right, #3a233f, transparent 1000px);
}

/* =================================
Input css
==================================== */

.Input_wrapper {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.Input_wrapper::placeholder {
  color: var(--text-tersury);
}

.Input_wrapper::-webkit-outer-spin-button,
.Input_wrapper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.primaryInput {
  text-align: left;
  direction: ltr;
  /* color: var(--text-primary); */
  color: #ffffff;
  box-shadow: var(--box-primary-shadow);
  border: 1px solid var(--text-tersury);
}

.secondaryInput {
  text-align: left;
  direction: ltr;
  color: var(--text-primary);
  box-shadow: var(--box-primary-shadow);
}
