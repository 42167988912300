:root {
  /* --body-main-bg: rgb(0, 0, 0); */
  --helper-main: black;
  --text-primary: white;
  --text-secondary: #4fc6ec;
  --text-tersury: #858585;
  --text-helper: #f087ff;
  --text-helper-tint: #4fc6ec;
  --text-heading: white;
  --btn-primary-bg: linear-gradient(260deg, #f087ff -4.52%, #4fc6ec 80%);
  --card-box-primary: ;
  --card-box-secondary: ;
  --card-border-primary: linear-gradient(45deg, #f087ff, #4fc6ec);
  --card-primary-bg: rgb(0, 0, 0);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  background-color: #000;
}

h1 {
  color: var(--text-heading);
  font-size: 2.5rem;
  font-weight: 700;
}

h3 {
  font-size: 2.3rem;
  text-transform: capitalize;
  color: var(--text-heading);
}

p {
  color: var(--text-primary);
  margin-bottom: 0 !important;
}

span {
  color: var(--text-tersury);
}

a {
  text-decoration: none;
}

ul {
  margin-bottom: inherit !important;
}

li {
  list-style: none;
}

.image-flip-coin-container {
  position: relative;
  margin: 10px auto;
  width: 153px;
  height: 153px;
  z-index: 1;
  perspective: 1000;
}

.image-flip-certificate-container {
  position: relative;
  width: 270px;
  height: 190px;
  z-index: 1;
  perspective: 1000;
}

.image-flip-card {
  width: 100%;
  height: 100%;
}

.image-flip-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.image-flip-face.back {
  display: block;
  transform: rotateY(180deg);
  text-align: center;
}

.image-flip-face.back {
  animation: backRotate 3000ms linear 0ms infinite;
}

.image-flip-face.front {
  animation: frontRotate 3000ms linear 0ms infinite;
}

.coin-image {
  width: 153px;
  height: 153px;
}

.certificate-image {
  width: 270px;
  height: auto;
}

@keyframes frontRotate {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

@keyframes backRotate {
  from {
    transform: rotateY(180deg);
  }

  to {
    transform: rotateY(540deg);
  }
}

/* =================================
Grid system Resuable Css 
==================================== */

.section {
  padding: 2rem 0;
}

.container,
header {
  max-width: 1352px !important;
  padding-left: 100px;
  padding-right: 100px;
}

/* ===========================================
Media Queries Section
======================================= */

/* for widht below 1200px  */
@media (max-width: 1200px) {

  .container,
  header {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* media queries less then 992px */
@media (max-width: 992px) {

  .container,
  header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Below 768px  */
@media (max-width: 768px) {
  .leftAdd {
    text-align: center !important;
    padding-right: 0em !important;
  }

  .addContentleft {
    width: 50%;
    text-align: center;
    margin: auto !important;
  }

  .addContentright {
    width: 50%;
    text-align: center;
    margin: auto !important;
  }

  .advisorImg .addimg {
    border: 4px solid #fff;
    padding: 1em;
    width: 55% !important;
    height: 15em !important;
    object-fit: cover;
  }

  .rightAdd {
    text-align: center !important;
    padding-left: 0em !important;
  }

  .mobAdd {
    margin-bottom: 2em;
  }

  .headVideo span,
  .headVideos span {
    font-size: 14px !important;
  }

  .container,
  header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .jobcard .accordion-header {
    border-radius: 3px;
    opacity: 0.66;
    background: linear-gradient(270deg, #6145b0 0%, #a16cd6 74.24%);
    box-shadow: 1px 8px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.5px);
    height: 2.2em !important;
    margin-bottom: 0;
  }

  .jobcard .accordion-button {
    border: none !important;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    /* opacity: 0.66; */
    background: linear-gradient(270deg, #6145b0 0%, #a16cd6 74.24%);
    box-shadow: 1px 8px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.5px);
    color: #fff;
    font-weight: 600;
    font-size: 20px !important;
  }
}

/* Below 576px  */
@media (max-width: 576px) {

  .container,
  header {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section {
    padding: 3rem 0;
  }

  h1 {
    font-size: 2.8rem;
  }
}

/* //////////////////////////////////// CUSTOM CSS ////////////////////////////////////////// */

.dashboardHeading {
  margin: 1rem 0;
}

.dashboardHeading p {
  margin-top: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.dashboardHeading h2 {
  color: #ffffff;
  font-weight: 900;
}

.yourCourse {
  color: #737272;
  font-weight: 700;
  border-bottom: 1px solid #737272;
  font-size: 13px;
}

.yourCourse:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
  text-decoration: none;
}

.dashboardCards {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.dashCardBoundary {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: linear-gradient(180deg,
      hsla(0, 0%, 100%, 0.21) 6.46%,
      hsla(0, 0%, 100%, 0.06));
  border-radius: 27px;
  height: 12em;
  padding: 1.5rem 2rem;
}

.dashCourseName {
  margin-bottom: 2rem;
}

.dashCourseName span {
  display: block;
  color: #ffffff;
  font-weight: 900;
  font-size: 15px;
}

.dashCourseButtons {
  display: flex;
  /* justify-content: space-between; */
}

.mainBackground .css-d9cy32-control {
  background-color: #fff !important;
}

.mainBackground .css-c8kj6f-control {
  background-color: #fff;
}

.mainBackground .css-1j12nnn-menu {
  background-color: #fff !important;
  color: #000 !important;
}

.dashView {
  background: transparent;
  border: 2px solid #9124a7;
  border-radius: 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 900;
  padding: 7px 15px;
}

.dashView:hover {
  color: #000;
  background: #fff;
}

.dashDownload {
  color: #fff;
  font-weight: 900;
  padding: 7px 15px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  background: linear-gradient(90deg,
      #4bc9f0 4.01%,
      #7ccdde 46.95%,
      #0273b3 90.26%);
  backdrop-filter: blur(70.5px);
  text-decoration: none !important;
  width: 15em;
  /* margin: 2em 0; */
  color: #fff;
}

.formIcon {
  color: #fff;
  font-weight: 900;
  padding: 7px 15px;
  font-size: 14px;
  border: none;
  border-radius: 7px;
  background: linear-gradient(90deg,
      #4bc9f0 4.01%,
      #7ccdde 46.95%,
      #0273b3 90.26%);
  box-shadow: 0px 3px 4px 0px rgba(106, 228, 255, 0.25);
  backdrop-filter: blur(70.5px);
}

.formIcon span {
  color: #fff;
}

.dashDownload:hover {
  color: white !important;
}

/* .PrimaryBtn {
  border-radius: 32px !important;
  background: linear-gradient(
    90deg,
    #a16cd6 11.45%,
    #dabbe7 53.14%,
    #6147aa 104.58%
  ) !important;
  backdrop-filter: blur(70.5px) !important;
} */
.PrimaryBtn {
  /* background: linear-gradient(90deg, #4984d7, #8a4bce) !important; */
  border-radius: 32px;
  background: linear-gradient(90deg,
      #04bbf5 11.83%,
      #9de0ef 51.3%,
      #00a0d2 100%) !important;
  backdrop-filter: blur(70.5px) !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dashboard-box {
  background-image: url(assets/images/nft-home-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 2rem;
}

.profile-dropdown:focus {
  box-shadow: none !important;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: transparent !important;
  border: 2px solid#9124A7 !important;
  box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border: 2px solid #9124a7 !important;
  box-shadow: none !important;
}

.dashlogo img {
  height: 3em;
  width: 40%;
  object-fit: contain;
}

.dashmenulist {
  display: flex;
  justify-content: end;
  align-items: baseline;
}

.dropdown-image {
  background: #000;
  height: 3.2em;
  width: 3.2em;
  object-fit: cover;
  border-radius: 50%;
}

.dropdown-menu {
  background: transparent;
  border: 1px solid #757577;
  padding: 0;
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
  font-size: 13px;
  text-align: center;
}

.dropdown-menu a {
  color: #fff !important;
}

.dropdown-menu a:hover,
li {
  text-decoration: none;
  font-weight: 700;
}

.dropdown-menu li:hover {
  background: linear-gradient(90deg, #4984d7 0%, #8a4bce 100%);
  color: #fff !important;
}

.dashmenu .btn:focus {
  box-shadow: none !important;
}

.dropdown-toggle::after {
  color: #fff !important;
  margin-left: 1em !important;
}

.firstdropmenu {
  border: none;
  margin-top: 0.5rem;
}

.firstdropmenu li {
  padding: 7px 0 !important;
  background: transparent !important;
  width: 68% !important;
}

.firstdropmenu a {
  padding: 8px;
  color: #fff !important;
  font-weight: 800;
  background-image: linear-gradient(260deg, #f087ff -4.52%, #4fc6ec 80%);
  border-radius: 3px;
}

.firstdropmenu a:hover {
  color: #000 !important;
  background: #fff;
}

.sideCard {
  padding: 3px;
  background-color: #fff;
  border-radius: 7px;
}

.innerSideCard {
  border: 1px solid #000;
  border-radius: 7px;
}

.cardSectionOne {
  /* border-bottom: 1px solid #000; */
  text-align: center;
  padding: 0.5rem 1rem;
}

.cardSectionOne h4 {
  font-weight: 900 !important;
  font-size: 15px;
  margin-bottom: 0.5rem !important;
}

.cardSectionOne img {
  height: 3em;
  width: 3em;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #4399e9;
  padding: 2px;
  margin: 0.5em 0;
}

.cardSectionOne p {
  font-size: 12px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0.2rem !important;
}

.cardSectionOne span {
  font-size: 10px;
  font-weight: 700;
}

.cardSectionTwo {
  display: flex;
  padding: 5px 15px;
  justify-content: space-between;
}

.optionMainImage {
  border: 2px solid #4399e9;
  background-color: #4399e9;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionImage span {
  font-size: 10px;
  color: #4399e9 !important;
  font-weight: 500;
}

.optionImage button {
  display: block;
  padding: 0;
  border: none;
  background-color: #fff;
}

.optionImage button:hover {
  background-color: transparent !important;
}

.optionFirst img {
  margin-top: 7px !important;
  height: 1.2em;
}

.optionSecond img {
  height: 0.8em;
}

.optionImage span {
  display: block;
  text-align: center;
}

.optionThird img {
  margin-top: 8px !important;
  height: 2em;
}

.optionFourth img {
  height: 2.2em;
}

.dashrightpart {
  padding: 0 5rem;
}

.cardSectionThree ul {
  border-bottom: 1px solid #000000 !important;
  margin-bottom: 0 !important;
}

.cardSectionThree li {
  width: 50% !important;
}

.cardSectionThree button {
  width: 100% !important;
  color: #4399e9;
  background-color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 5px !important;
}

/* .cardSectionThree button:hover {
  border: none !important;
  border-bottom: 3px solid #4399E9 !important;
} */

.cardSectionThree .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  border-bottom: 3px solid #4399e9 !important;
  color: #4399e9;
}

.tab-content>.tab-pane {
  /* display: block !important; */
}

.tab-content>.active {
  /* display: block !important; */
}

.wallet {
  position: relative;
}

.walletCard {
  position: absolute;
  top: 4em;
  right: -7em;
  width: 18em;
  /* height: 25em; */
  z-index: 9999 !important;
}

.walletMenu {
  margin: 0 0 0 14px !important;
  height: 1.4em !important;
}

.wallet button {
  background-color: transparent;
  border: none !important;
}

.tab-token-upper {
  height: 8em;
  overflow-y: scroll;
}

.tab-token-upper .accordion-header {
  margin: 0 !important;
  text-align: left !important;
}

.tab-token-upper .accordion-button {
  text-align: left !important;
  border: none !important;
  border-bottom: 1px solid #000 !important;
  padding: 0.5rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.slideBox {
  display: flex;
}

.slideIcon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 0.5rem;
}

.slideIcon img {
  height: 1.5em !important;
}

.slideContent {
  width: 100% !important;
}

.slideContent p {
  color: #000 !important;
  font-weight: 900 !important;
  font-size: 10px;
  margin-bottom: 4px !important;
}

.slideContent span {
  color: #b4b5ba !important;
  font-weight: 700 !important;
  font-size: 8px;
}

.accordion-body p {
  color: #000 !important;
  font-size: 12px;
  padding: 0.5rem;
  text-align: justify;
}

.tab-token-lower {
  padding: 6px;
}

.tab-token-lower p {
  color: #000;
  font-size: 10px !important;
  font-weight: 700 !important;
}

.tab-token-lower a {
  color: #4399e9 !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  margin: 0 !important;
}

.tab-token-lower a:hover {
  text-decoration: underline !important;
}

.navbar-for-mobile {
  display: none !important;
}

.bs-popover-bottom {
  transform: translate3d(55rem, 5rem, 0) !important;
  position: fixed !important;
}

@media (max-width: 767px) {
  .bs-popover-bottom {
    transform: translate3d(5rem, 4rem, 0) !important;
  }

  .navbar-for-mobile {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .hamburger-for-mob {
    display: none !important;
  }

  .enquiry-button-box {
    text-align: center;
    margin-top: 3rem;
  }

  .navbar-for-mobile .nav-link {
    font-size: 14px !important;
  }

  .dashCardBoundary {
    margin-top: 1rem;
  }

  .dashlogo {
    text-align: center;
  }

  .dashlogo img {
    height: 3em;
    width: 50%;
  }

  .dashrightpart {
    padding: 0 1rem;
  }

  .tab-token-upper {
    height: 9em;
    overflow-y: scroll !important;
  }

  .dropdown-image {
    height: 2.5em;
    width: 2.5em;
  }

  .wallet img {
    height: 1.5em;
  }

  .firstdropdown button {
    padding: 4px;
  }

  .dashmenulist {
    justify-content: space-between;
    margin-top: 1rem;
  }

  .dashboard-box {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wallet-handler .dropdown .dropdown-item {
    width: 80% !important;
  }

  .navbar-fixing {
    padding: 1rem 0 !important;
  }

  .wallet-handler {
    width: 10em !important;
    position: absolute !important;
    right: 2.5em !important;
    top: 0.5em !important;
  }

  .wallet-handler .dropdown .dropdown-item {
    margin: 2px 6px !important;
    width: 41%;
    background: transparent !important;
    text-align: center !important;
    font-size: 13px !important;
  }

  .data-heading {
    font-size: 17px;
    width: 100%;
  }

  .walletPic {
    display: block !important;
    height: 1.7em !important;
  }

  .dashrightpart {
    padding: 0 3rem;
  }

  .tab-token-upper {
    height: 10em;
    overflow-y: scroll;
  }

  .enquiry-button-box {
    text-align: center !important;
    margin-top: 3rem !important;
  }

  .bs-popover-bottom {
    transform: translate3d(29rem, 5rem, 0) !important;
  }

  .navbar-for-mobile {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .hamburger-for-mob {
    display: none !important;
  }

  .login-form-box {
    width: 75% !important;
    transform: translate(0, 50%) !important;
  }

  .otpColumn label {
    margin: 7px 30px !important;
  }

  .otpColumn input {
    margin: 0 24px !important;
    width: 2em !important;
  }
}

/* ///////////////////////////////////////////////////////////////// */

.redirect-button {
  background: linear-gradient(90deg, #4984d7 0%, #8a4bce 100%);
  color: #fff;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 12px;
  text-decoration: none !important;
}

.redirect-button:hover {
  color: white !important;
  text-decoration: none !important;
}

.Toastify {
  position: relative;
  z-index: 999999999999999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.formCard .dashDownload {
  border-radius: 8px;
  font-size: 16px;
  padding: 6px 3em;
  margin-top: 10px;
  width: 100%;
  text-decoration: none !important;
}

.section-hero-slider {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 20px 0px;
}

.gradient-div {
  width: 100%;
  /* background: linear-gradient(
    90deg,
    #4bc9f0 4.01%,
    #7ccdde 46.95%,
    #0273b3 90.26%
  ) !important; */
  border-radius: 30px;
  padding: 6px 6px !important;
  border: 6px solid rgba(79, 198, 236, 0.78);
}

.inner-form {
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.479); */
  border-radius: 24px;
  padding: 0px !important;
  border: none !important;
}

.formCard {
  border-radius: 24px;
  backdrop-filter: blur(1px);
  text-align: center;
  padding: 2em 4em;
  width: 100%;
  margin: auto;
  border: none !important;
}

.formCard .form-section input {
  width: 100%;
  border: none;
  border-radius: 7px;
  /* background: linear-gradient( 90deg, #4bc9f0 4.01%, #7ccdde 46.95%, #0273b3 90.26% ); */
  -webkit-backdrop-filter: blur(70.5px);
  backdrop-filter: blur(70.5px);
  height: 35px;
  padding-left: 10px;
  color: white !important;
  border-radius: 7px;
  background: linear-gradient(90deg,
      #01a9de 7.31%,
      #82cedf 47.39%,
      #02639a 96.84%);
  backdrop-filter: blur(70.5px);
}

.formCard .form-section select {
  width: 100%;
  border: none;
  /* background: linear-gradient(360deg, #3D3D3D 0%, rgba(61, 61, 61, 0.00) 100%);
  backdrop-filter: blur(1.5px); */
  border-radius: 7px;
  background: linear-gradient(90deg,
      #01a9de 7.31%,
      #82cedf 47.39%,
      #02639a 96.84%);
  backdrop-filter: blur(70.5px);
  height: 35px;
  padding-left: 10px;
  color: white !important;
}

.formCard .form-section select option {
  background-color: #6145b0 !important;
}

.formCard .form-section input::placeholder {
  color: #fff !important;
}

.formIcon {
  margin-bottom: 1em;
}

.formCard .form-section {
  height: 3em;
  margin-bottom: 0.3em !important;
}

/* .border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-radius: 20px;
}
.border-gradient-purple {
  border-image: linear-gradient(45deg,red,blue)10;
  border-radius: 10px;
} */

.css-aoxytf-singleValue {
  color: #000 !important;
}

.Signup .inputColumn {
  height: 4em !important;
}

.password-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg,
      #04bbf5 11.83%,
      #9de0ef 51.3%,
      #00a0d2 100%) !important;
  color: white;
  cursor: pointer;
  width: 8%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.password-toggle .toggle-icon {
  color: white !important;
  width: 70% !important;
  height: 70% !important;
}

.addSection {
  background-color: #000;
  padding: 5em 0;
}

.advisorImg {
  /* text-align: center; */
}

.advisorImg .addimg {
  border: 4px solid #fff;
  height: 22em;
  object-fit: cover;
  padding: 1em;
  width: 100%;

}

.advisorImg p {
  padding: 0.5em 0;
  font-size: 20px;
  font-weight: 600;
}

.addImg {
  margin-top: 4em;
  width: 100%;
}

.leftAdd {
  text-align: right;
  padding-right: 2em;
}

.rightAdd {
  text-align: left;
  padding-left: 2em;
}

.addContentleft {
  margin-left: auto;
  text-align: center;
  /* width: 60%; */

}

.addContentright {
  margin-right: auto;
  text-align: center;
  /* width: 60%; */
}