.section-login .section-login-title {
  display: flex;
  justify-content: center;
}

.section-login .section-login-title h4 {
  color: #ffffff;
  font-weight: 600;
}

.section-login form {
  width: 100%;
}

.section-login form .form-handle {
  position: relative;
  /* margin-top: 15px; */
}

.section-login .section-login-links {
  text-align: center;
  margin-top: 25px;
}

.section-login .section-login-links a {
  color: var(--text-secondary);
}

/* ////////// custom styles ///////////// */

.customButton {
  border-radius: 0px 8px 8px 0px;
  padding: 0.7rem 1rem 0.7rem 0.5rem !important;
  font-size: 13.5px !important;
  display: flex !important;
}

.login-box {
  background-image: url(../assets/images/nft-home-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 17em;
}

.login-form-box {
  /* border: 2px solid #8153cf; */
  width: 50%;
  margin: auto;
  padding: 2rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 6.46%, rgba(255, 255, 255, 0.06) 100%);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  transform: translate(0%,30%) !important;
}

.customButton p {
  color: #fff !important;
  margin-left: 1px !important;
  width: 15px !important;
  display: block !important;
  text-align: center;
}

.inputColumn {
  padding: 0 !important;
  height: 6em;
}

.logColumn {
  margin-left: 0px !important;
  display: flex;
  justify-content: center;
}

.error {
  color: red;
  display: block !important;
  font-size: 13px;
  margin-top: 4px;
}

.verify-button {
  border-radius: 8px !important;
  width: 40% !important;
  margin: auto !important;
  font-size: 13px;
  margin-top: 10px !important;
}

.customInputField {
  border-radius: 8px 0px 0px 8px !important;
  width: 78% !important;
  padding-left: 10px;
}

.customInputField::placeholder {
  text-align: left;
}

.otpColumn input {
  width: 3em !important;
  background-color: transparent;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  margin: 0 14px !important;
}

.otpColumn input:focus-visible {
  border: none !important;
}

.otpColumn span {
  display: none;
}

.otpColumn label {
  font-weight: 800 !important;
  color: grey;
  margin: 7px 3px;
}

@media(max-width:767px) {
  .customInputField {
    border-radius: 8px 0px 0px 8px !important;
    width: 100% !important;
    padding-left: 10px;
  }

  .login-box {
    background-size: 200%;
    padding-bottom: 22em !important;
  }

  .otpColumn input {
    width: 2.5em !important;
    margin: 0 6px !important;
  }

  .mobile-row {
    margin: 0 !important;
  }

  .customButton {
    padding: 15px 10px !important;
    font-size: 11px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .mobResponseColumn {
    padding: 0 1rem;
  }

  .logColumn {
    display: flex !important;
  }

  .logColumn input {
    width: 65% !important;
  }

  .generateButton {
    width: 30% !important;
  }

  .login-form-box {
    width: 100%;
    padding: 1rem !important;
    transform: translate(0, 50%) !important;
  }

  .sendotpbutton{
    font-size: 13px !important;
    width: 8em !important;
  }
}

.otp-input {
  text-align: left;
  direction: ltr;
  color: rgb(0, 0, 0) !important;
  border: 1px solid grey !important;
}

.otpColumn div {
  justify-content: space-between !important;
  margin-top: 1em;
}

.sendotpbutton{
  font-size: 16px;
  width: 9em;
}

.sendotpbutton:hover{
  color: #fff !important;
}

.otp-count{
  margin: 0 5px !important;
  width: 5px !important;
}