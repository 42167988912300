/* =================================
Hero Section Css
==================================== */
.section-hero {
  background-image: url(../assets/images/bghero.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
  margin: 0em 0em;
}
.section-hero h1 span{
  color: #63D5F9;
}
.section-hero h4{
  color: #fff;
  border-top: 1px solid #fff;
  width: 75%;
  padding-top: 0.6em;
}
.section-hero-group span {
  color: #fff !important;
}
.section-hero .section-hero-data h1 {
  line-height: 60px;
}

.section-hero-data .section-hero-group {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin-top: 15px;
}

.section-hero-data .section-hero-group span {
  position: relative;
  font-size: 1.2rem;
  display: block;
  padding-left: 2.5rem;
}

.section-hero-data .section-hero-group span::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 25px;
  width: 25px;
  background: #fff;
  border: 8px solid #00B3EC;
  border-radius: 50%;
  content: " ";
}

/* .section-hero-data .section-hero-group span:nth-child(2)::before {
  border-color: var(--text-secondary);
}
.section-hero-data .section-hero-group span:nth-child(3)::before {
  border-color: #4268db;
} */

.section-hero-data span a {
  text-decoration: none;
  color: var(--text-secondary);
}
.section-hero .section-hero-slider .mySwiper .swiper-pagination-bullet {
  background-color: var(--text-primary);
}

.section-hero .section-hero-slider .mySwiper .swiper-pagination-bullet-active {
  background-color: var(--text-secondary) !important;
}
.section-hero .section-hero-slider .mySwiper {
  max-width: 80%;
  height: 420px;
}

.section-hero .section-hero-slider .mySwiper img {
  max-width: 100%;
  object-fit: cover;
}

.section-hero .section-hero-slider .section-hero-content {
  display: flex;
  flex-direction: column; 
  gap: 10px 0;
  z-index: 10;
}
.section-hero .section-hero-slider .section-hero-content h3 {
  font-size: 1.8rem;
}

/* =================================
Skills  Section Css
==================================== */

.section-skills .section-skills-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.section-skills .section-skills-heading span {
  max-width: 80%;
  margin-top: 1rem;
}

.section-skills .mySwiper {
  padding: 10px;
}

.section-skills .mySwiper img {
  width: auto;
  height: 10rem;
}

.section-skills .mySwiper p {
  font-size: 1.15rem;
}

.section-skills .section-skills-list .section-skills-content {
  z-index: 10;
}

/* =================================
 Courses Section Css
==================================== */

.section-why-us .section-why-us-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.section-why-us .section-why-us-list .scecondaryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-why-us .section-why-us-list .scecondaryCard {
  position: relative;
  width: 100%;
}

.section-why-us .section-why-us-list .scecondaryCard .counter {
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: var(--text-secondary);
  border-radius: 50px;
  padding: 0.3rem 0.9rem;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.section-why-us .section-why-us-list .scecondaryCard .counter p {
  font-weight: 600;
  font-size: 1.1rem;
}

.section-why-us .section-why-us-list .scecondaryCard h3 {
  font-size: 1.5rem;
}

.section-why-us .section-why-us-list .scecondaryCard span {
  font-size: 1.1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.section-why-us .section-why-us-list .scecondaryCard img {
  width: auto;
  height: 8rem;
}

.section-why-us .section-why-us-list .section-why-us-content {
  z-index: 10;
}

/* .mainBg {
  background-color: #000000 !important;
  border-radius: 10px;
} */
