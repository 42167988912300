.about-us-section .heading-text-1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
}
.about-us-section .heading-text-2 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #ffffff;
}
.about-us-section p {
  color: #ffffff !important;
}
.about-us-section .subheading-text-1 {
  font-size: 1.1rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

.privacy-policy-section .heading-text-1 {
  color: var(--text-heading);
  font-size: 2.5rem;
  font-weight: 700;
}
.privacy-policy-section .heading-text-2 {
  font-size: 1.6rem;
  font-weight: 500;
}
.privacy-policy-section .heading-text-3 {
  font-size: 1.8rem;
  font-weight: 700;
}
.privacy-policy-section .list-1 li {
  list-style-type: initial;
  padding-top: 5px;
  padding-bottom: 5px;
}

.privacy-policy-section .subheading-text-1 {
  font-size: 1.3rem;
  font-weight: 700;
}

.terms-and-conditions-section .heading-text-1 {
  color: var(--text-heading);
  font-size: 2.5rem;
  font-weight: 700;
}
.terms-and-conditions-section .heading-text-2 {
  font-size: 1.6rem;
  font-weight: 500;
}
.terms-and-conditions-section .subheading-text-1 {
  font-size: 1.3rem;
  font-weight: 700;
}
.terms-and-conditions-section .phone-number {
  text-decoration: none;
  color: #fff;
}

.about-us-section .heading-text-2 {
  position: relative;
}

.about-us-section .heading-text-2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  background: linear-gradient(90deg, #4984d7, #7abef7) !important;
  width: 2.5em;
  height: 3px;
  border-radius: 100px;
}
.about-us-section .heading-text-1 span{
  color: #4cccf1;
}

