.nft_login_section {
  font-family: 'Montserrat';
  height: 100vh !important;
  overflow: hidden;
}

.nft_login_container {
  width: auto;
  height: 715px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nft_login_token_right {
  width: 240px;
  height: auto;
  margin-top: 436px;
}

.nft_login_token_left {
  width: 240px;
  height: auto;
  margin-top: 77px;
}

.nft_login_card {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  min-height: 370px;
  margin-top: 176px;
  padding: 30px 45px;
  max-width: 500px;
}

.nft_login_card h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #f7f7f7;
}

.nft_login_card img {
  height: 54px;
  width: auto;
}

.nft_login_card_login_txt {
  font-weight: 700;
  font-size: 20px;
  color: #f7f7f7;
  border-bottom: 1px solid #818181;
  width: 75px;
  margin: auto;
  padding: 8px;
}

.nft_login_form_input input {
  border-radius: 0px;
  border: 0px;
  border-bottom: 2px solid #791f89;
  color: #ffffff !important;
  font-size: 16px;
  background: transparent;
}

.nft_login_form_input input:hover,
.nft_login_form_input input:focus {
  background: transparent !important;
  border-bottom: 2px solid #791f89 !important;
}

.nft_login_form_input input::placeholder {
  color: #666666;
}

.nft_login_form_input span {
  text-align: left;
}

.nft_login_form_input_space::placeholder {
  letter-spacing: normal;
}

.nft_login_form_input_space {
  letter-spacing: 6px;
}

.nft_login_otp_text {
  font-weight: 500;
  font-size: 16px;
  color: #f7f7f7;
}

.nft_login_otp_timer span {
  font-weight: 500;
  font-size: 16px;
  color: #19afde;
}

.nft_login_otp_timer button {
  background: none !important;
}

.nft_login_otp_text_email {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #c8c8c8;
}

/* nft-home */
.nft_home_section {
  font-family: 'Montserrat';
  background: #151515;
  min-height: 100vh;
  padding-bottom: 50px;
  height: 100%;
}

.nft_home_container {
  width: auto;
  height: 715px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nft_home_welcome {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #f7f7f7;
  margin-top: 20px;
}

.nft_home_txt_1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #f7f7f7;
  margin-top: 18px;
}

.nft_home_txt_2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #a4a4a4;
  margin-top: 16px;
}

.nft_home_card {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  padding: 24px 32px;
  margin-top: 25px;
}

.nft_home_card p {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
}

.nft_home_card_view {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #f7f7f7;

  padding: 10px 16px;
  max-width: 74px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  border: 2px solid #ad2fca;
  cursor: pointer;
}

.nft_home_card_download {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #f7f7f7;

  background: linear-gradient(224.36deg, #ad2fca 0%, #19afde 100%);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  margin-left: 20px;
  padding: 10px 16px;
  max-width: 118px;
  cursor: pointer;
}

.nft_home_card_download a {
  text-decoration: none !important;
  color: #f7f7f7 !important;
}

.nft_home_card_download a:hover {
  color: #f7f7f7 !important;
}

.nft_home_top_logo {
  height: 40px;
  width: auto;
  margin-top: 14px;
}

.nft_home_top_profile {
  display: inline-block;
  color: #fff;
}

.nft_home_top_profile_wallet {
  color: #fff;
  margin-right: 5px;
  display: inline-block;
}

.nft_home_top_profile_img {
  width: 56px;
  height: auto;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(255, 255, 255, 0.06);
}

.nft_home_top_dropdown {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(5px);
}

.nft_home_top_profile_icon {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.nft_home_top_profile_icon:hover {
  background: none;
  opacity: 0.9;
}

.nft_home_top_profile_icon span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

.nft_home_top_dropdown_icon {
  width: 24px;
  height: auto;
}

.server_error {
  font-weight: 500;
  font-size: 15px;
  color: #dc3545;
  min-height: 20px;
}

.data_not_found {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.nft_certificate_modal_overlay {
  background: rgba(255, 255, 255, 0.02) !important;
  backdrop-filter: blur(5px) !important;
  opacity: 1 !important;
}

.nft_certificate_modal_dialog {
  max-width: 1050px;
}

.nft_certificate_modal_content {
  border: 2px solid rgba(255, 255, 255, 0.11);
  background: rgba(255, 255, 255, 0.02);
  border-radius: 24px;
  position: relative;
}

.nft_certificate_modal_body {
  width: 100%;
  overflow: scroll;
}

.nft_certificate_modal_close {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 10;
}

.nft_certificate_modal_bg_score {
  width: 905px;
  min-height: 640px;
  height: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin: auto;
  padding-bottom: 90px;
}

.nft_certificate_modal_bg_certificate {
  width: 905px;
  height: 640px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.nft_modal_heading_1 {
  font-size: 19px;
  font-weight: 900;
  padding-top: 48px;
  text-align: center;
  color: #fff;
}

.nft_certificate_btn {
  font-size: 11px;
  font-weight: 700;
  background: #f2f0f1;
  text-align: center;
  height: 26px;
  padding: 0px 2px;
  line-height: 24px;
  border-radius: 26px;
  position: absolute;
  bottom: 45px;
  left: 363px;
}

.nft_certificate_btn span {
  border: 2px solid #151515;
  border-radius: 20px;
  color: #151515;
  width: 178px;
  display: inline-block;
  height: 23.5px;
  line-height: 20px;
}

.nft_modal_score_table {
  max-width: 706px;
  margin: auto;
  margin-top: 0px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
}

.nft_modal_score_table thead {
  font-size: 14px;
  font-weight: 700;
}

.nft_modal_score_table thead th {
  border-top: 0px;
  padding-bottom: 5px;
}

.nft_modal_score_table tbody td {
  padding: 5px;
}

.nft_modal_score_table_last_col {
  font-size: 13px;
  font-weight: 700;
}

.nft_certificate_name_section_1 {
  text-align: center;
  padding-top: 135px;
  max-width: 420px;
  margin: auto;
  height: 358px;
  overflow: hidden;
}

.nft_certificate_name_section_1 h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.nft_certificate_name_section_1 p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nft_certificate_name_section_1 div {
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: center;
}

.nft_certificate_name_section_1 h4 {
  color: #151515;
  font-size: 32px;
  font-weight: 900;
}

.nft_certificate_name_section_2 {
  text-align: center;
  padding-top: 10px;
  max-width: 420px;
  margin: auto;
  overflow: hidden;
  height: 172px;
}

.nft_certificate_name_section_2 p {
  font-size: 20px;
  font-weight: 500;
}

.nft_certificate_name_section_2 h4 {
  color: #151515;
  font-size: 28px;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

.nft_certificate_name_section_2 div {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .nft_login_card {
    margin-top: 20px;
  }

  .nft_login_card img {
    height: 48px;
  }

  .nft_login_token_left {
    width: 140px;
    height: auto;
    margin-top: 20px;
  }

  .nft_login_token_right {
    width: 140px;
    height: auto;
    margin-top: 20px;
  }

  .nft_home_top_logo {
    height: auto;
    width: 200px;
  }
}


/* ////////////////// custom csss ///////////////////////////////////////////////////////////// */
.nft-page-container {
  padding: 5px;
}

/* .nft-image-top {
  margin-top: 2rem;
} */

.nft-image-top img {
  height: 13em;
  width: 100%;
  object-fit: contain;
}

.nft-content-gradient {
  /* width: 70%; */
  margin: auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 6.46%, rgba(255, 255, 255, 0.06) 100%);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border-radius: 27px;
  padding: 1rem 1rem 1rem 1rem;
  color: #fff;
  /* margin-top: 2rem; */
}

.nft-content-gradient ul {
  padding-left: 1em !important;
}

.nft-content-gradient li {
  list-style: decimal;
  font-size: 13px;
  font-weight: 600;
}

.nft-content-gradient span {
  color: rgb(12, 153, 12);
  font-weight: 800;
  font-size: 13px;
}

.nft-content-gradient p {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.nft-content-gradient img {
  /* height: 2.5em; */
  object-fit: contain;
  margin: 5px 5px;
  width: 100%;
}

.nft-content-gradient button {
  color: rgb(12, 153, 12);
  background-color: transparent;
  font-weight: 800;
  font-size: 13px;
  width: 100%;
  border: none !important;
}

/* .nft-image-bottom {
    margin: 2rem 0;
  } */

.nft-image-bottom img {
  height: 13em;
  width: 100%;
  object-fit: contain;
}

.nft-content-form {
  padding: 0 3rem;
  margin-top: 1rem;
  text-align: center;
}

.nft-content-form input {
  border: none;
  background-color: rgba(168, 167, 167, 0.386);
  padding: 4px 10px;
}

.nft-content-form input::placeholder {
  color: rgb(187, 183, 183);
  font-size: 14px;
}

/* .nft-page-container {
  border: 2px solid red;
} */

.errorShow {
  color: #fff !important;
    font-size: 10px !important;
    font-weight: bold;
    text-align: left;
    margin: 0;
}

.form-section {
  height: 3.3em;
}

.new-nft-two {
  margin: 6rem 0 !important;
}

.new-certificate-code {
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 20px !important;
  font-style: normal;
  font-weight: normal !important;
  line-height: normal;
}

.new-certificate-code-submit{ 
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 20px !important;
  font-style: normal;
  font-weight: normal !important;
  line-height: normal;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
 }

.custom-form-section {
  border-bottom: 1px solid #AD2FCA;
}

@media (max-width: 768px) {
  .nft-content-gradient {
    width: 100%;
  }

  .nft-content-form {
    padding: 0 2rem;
  }

  .new-nft-two {
    margin: 3rem 0 !important;
  }

  .nft-image-top img {
    height: 7em !important;
  }

  .nft-image-bottom img {
    height: 7em !important;
  }
}

/* .submit-button {
  background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%);
    color: #FFF !important;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px; 
    padding: 8px 20px 8px 20px; 
    border-radius: 20px;
    text-decoration: none !important;
} */

/* .submit-button:hover{
  color: white !important;
  text-decoration: none !important;
} */

.form-section input {
  color: #D3C6C6;
  padding: 10px;
  border-radius: 6px;
  background-color: #363535;
  width: 100%;
}

.form-section input::placeholder {
  color: #D3C6C6;
}


.mainBackgroundss {
  background-image: url(../assets/images/nft-home-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  padding-bottom: 0em;
}

.nft-page-row {
  position: relative;
  z-index: 999999;
}

.valid-certify-right-image {
  display: flex;
  flex-direction: column-reverse;
}

.new-certificate-holder {
  color: #00BE35;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: normal !important;
  line-height: normal;
}

.reset-certificate {
  color: white !important;
  font-size: 15px !important;
}

.certificate-holder-name {
  color: #D3C6C6 !important;
  padding: 10px !important;
  border-radius: 6px !important;
  background-color: #363535 !important;
  width: 100% !important;
  text-align: left !important;
}