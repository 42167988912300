/* =================================
Global Resuable Css 
==================================== */

.para {
  color: var(--text-tersury);
  font-size: 1.2rem;
}

.not-allowed {
  cursor: not-allowed !important;
}
