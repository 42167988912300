/* ================================== 
 Header section css  
=============================== */

.header {
  background-color: var(--body-main-bg);
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  transition: all 0.3;
  -webkit-transition: all 0.3;
  -moz-transition: all 0.3;
  -ms-transition: all 0.3;
  -o-transition: all 0.3;
}

.header .logo_section .logo_link {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  text-decoration: none;
}

.header .logo_section {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.header .logo_section img {
  height: 3.1rem;
}

.header .logo_section span {
  font-size: 1.6rem;
  color: var(--text-primary);
  font-weight: 600;
}

.header .navbar .navbar-list {
  display: flex;
  gap: 1.4rem;
  list-style: none;
}

.header .navbar .navbar-list li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .navbar .navbar-list .navbar-link {
  color: #ffffff;
  /* color: #fff; */
}

.header .navbar .navbar-list .navbar-link:link {
  text-decoration: none;
  display: inline-block;
  font-size: 1.15rem;
  font-weight: 500;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.header .navbar .navbar-list .navbar-link:hover {
  color: #4cccf1;
}

.header .navbar .navbar-list .active-nav {
  color: #4cccf1;
}

.header .mobile-navbar-btn {
  display: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  line-height: 0;
}

.header .mobile-navbar-btn .mobile-nav-icon {
  width: 2rem;
  height: 2rem;
  color: #fff !important;
}

.header .mobile-navbar-btn .mobile-nav-icon[name="close-outline"] {
  display: none;
}

/* sticky Navbar */
.sticky .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  width: 100%;
  background-color: #000000;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
  z-index: 9999;
  margin: 0 auto;
}
.jobImg img {
  width: 100%;
}
@media (max-width: 660px) {
  .headVideo h5 {
    padding: 3em 0 0em !important;
    color: #000;
    font-size: 24px;
  }
  .learning {
    padding: 3em 0 !important;
  }
  .benefitCards img {
    width: 3em !important;
  }
  .mobrespo {
    width: 50% !important;
  }
  .benefitCards {
    border-radius: 7px;
    background: #fff;
    background-blend-mode: screen;
    box-shadow: 0px 6px 9px 1px rgba(214, 50, 255, 0.89),
      0px 8px 4px 0px #63d5f9;
    width: 100%;
    text-align: center;
    padding: 1em 1em 1em !important;
    margin-bottom: 2em !important;
    height: 10em !important;
  }
  .jobCards ul {
    padding: 1em 1em 1em 3em !important;
    margin-bottom: 0 !important;
  }
  .jobHead h5 {
    width: 100% !important;
  }
  .jobDetail p {
    color: #000;
    padding-top: 3em;
    font-size: 16px !important;
    font-weight: 600;
  }
  .BenefitSection {
    margin: 0 !important;
  }
  .benefitCards p {
    font-size: 14px !important;
  }
  .gradient-div {
    margin: 0em 1em;
  }
  .formCard {
    padding: 2em 1em !important;
  }
  .section-hero .section-hero-data h1 {
    line-height: 40px !important;
    font-size: 30px !important;
    text-align: center !important;
  }
  .header .navbar .navbar-list .navbar-link {
    color: #ffffff !important;
  }
  .footContent p {
    color: #857878;
    font-size: 16px !important;
    padding-top: 1em;
  }
  .topMargin {
    margin-top: 0 !important;
  }
  .jobSection p {
    color: #000;
    padding: 3em 0 0;
    font-size: 14px !important;
    font-weight: 600;
  }
  .innerCard {
    border-radius: 6px;
    border: 9px solid rgba(93, 0, 117, 0.89);
    opacity: 0.48;
    background: #c97deb;
    box-shadow: 7px 24px 7px 0px rgba(0, 0, 0, 0.25);
    height: 90% !important;
    width: 90% !important;
    position: absolute;
    left: 5% !important;
    top: 5% !important;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .mobpadd {
    padding: 0em 1em !important;
  }
  .videoCards {
    margin: 2em 4em !important;
  }
  .videocard {
    height: 16em !important;
  }
  .header {
    position: relative;
  }
  .navbar {
    background-color: #000000 !important;
  }
  .mobile-navbar-btn .icon-inner,
  .ionicon,
  svg {
    display: block;
    height: 100%;
    width: 100%;
    color: #000;
  }
  .header .mobile-navbar-btn {
    display: block;
    z-index: 999;
  }

  .header .navbar {
    width: 100%;
    height: 100vh;
    background-color: var(--body-main-bg);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: all 0.4s linear;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -ms-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 999;
  }

  .header .navbar .navbar-list {
    flex-direction: column;
    align-items: center;
  }

  .active > .navbar {
    width: 100%;
    height: 100vh;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .active > .mobile-navbar-btn .mobile-nav-icon[name="close-outline"] {
    display: block;
  }

  .active > .mobile-navbar-btn .mobile-nav-icon[name="menu-outline"] {
    display: none;
  }
}
