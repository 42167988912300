.capability-card {
  border-radius: 25px !important;
  background-color: #000 !important;
}

.gradient-box {
  margin-bottom: 1.5rem !important;
  border-radius: 25px;
  padding: 3px !important;
  background: linear-gradient(180deg, #77afff  0%, #bde0fc 100%) !important;
}

.gradient-box:hover .capability-card-image img {
  width: 120%;
  height: 180px;
}

.capability-heading h2 {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
  font-family: Montserrat !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.frame {
  height: 150px;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  /* border: 2px solid #fff; */
}

.capability-card-image img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 25px;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
  cursor: pointer;
}

.premium-para {
  border: 1.5px solid #fff;
  display: inline;
  background: linear-gradient(
    90deg,
    #04bbf5 11.83%,
    #9de0ef 51.3%,
    #00a0d2 100%
  ) !important;
  padding: 4px 15px;
  border-radius: 10px;
  position: absolute;
  /* left: 0px; */
  top: 26px;
  font-size: 10px;
  border-left: none !important;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  left: 18px;
}

.capability-heading h4 {
  font-size: 15px;
  color: #fff;
  text-align: center;
  font-weight: 900;
  margin-top: 10px;
  padding: 10px 5px;
  height: 4em;
}

.capability-content {
  padding: 0 0.5rem;
  margin: 0 !important;
  height: 9.5em;
}

.capability-content p {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #e6e6e6 !important;
}

.capability-content .white {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #fff !important;
}

.capability-content li {
  color: #e6e6e6;
  font-size: 12px;
  padding: 0 5px;
  margin: 2px 0 !important;
  list-style: none;
  font-weight: 400 !important;
}

.capability-footer {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;  
}

.free{
  display: block !important;
  padding-top: 0 !important;
}

.free p{
  text-align: center !important;
}

.courseHeading h4{
  color: #fff;
  margin-bottom: 0.5em;
}

.free a{
  margin: auto !important;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  margin-top: 0.5em !important;
}

.capability-footer p {
  font-weight: 900 !important;
  color: #fff;
  font-size: 13px;
}

.capability-footer span {
  font-weight: 500 !important;
  color: #b3b3b3;
  font-size: 11px;
}

.capability-footer a {
  border: none;
  background: linear-gradient(
    90deg,
    #04bbf5 11.83%,
    #9de0ef 51.3%,
    #00a0d2 100%
  ) !important;
  /* background: #fff !important; */
  padding: 7px 13px;
  border-radius: 11px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none !important;
}

.capability-footer a:hover {
  text-decoration: underline !important;
}

.capability-background {
  background-image: url(../assets/images/nft-home-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  padding-bottom: 0em;
}

@media (max-width: 767px) {
  .frame {
    height: 180px;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
  }

  .capability-content p {
    font-size: 16px !important;
  }

  .capability-content li {
    font-size: 16px !important;
  }

  .capability-card-image img {
    height: 180px;
    width: 100%;
  }

  .gradient-box:hover .capability-card-image img {
    height: 200px;
    width: 120%;
  }

  .capability-heading h4 {
    font-size: 18px;
    margin: 1rem 0;
  }

  .capability-content {
    font-size: 15px;
    height: 14em !important;
    padding-left: 2rem !important;
  }

  .capability-footer p {
    font-size: 17px !important;
  }

  .capability-footer span {
    font-size: 14px;
  }

  .capability-footer button {
    font-size: 15px;
  }

  .premium-para {
    font-size: 17px !important;
    left: 16.5px !important;
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .capability-footer a {
    text-align: center !important;
    font-size: 11px !important;
  }

  .frame {
    height: 160px;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
  }

  .capability-card-image img {
    height: 160px;
    width: 100%;
  }

  .gradient-box:hover .capability-card-image img {
    height: 180px;
    width: 120%;
  }
}
