.wallet-handler {
    padding-left: 10px;
    padding-right: 10px;
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%);
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    border-radius: 6px;
    text-align: center;
}

.wallet-handler #basic-nav-dropdown {
    color: white;
}

.website-logo {
    /* height: 2.5em; */
    object-fit: contain;
    width: 100%;
}

.navbar-background {
    /* margin-top: 0.5rem; */
    background-color: transparent !important;
    margin-top: 0;
    padding: 0 !important;
    padding-top: 0.5rem !important;

}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -1.25em !important;
    top: 2.5em !important;
    border: none !important;
}

.wallet-handler .dropdown .dropdown-item {
    padding: 10px 0;
    margin: 13px auto;
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%);
    text-align: center;
    border-radius: 6px;
    width: 86%;
}

.walletPic {
    height: 1.4em;
    margin-left: 1rem;
    display: none !important;
}

.popover-body {
    padding: 0 !important;
    width: 20em !important;
}

.mainBackground {
    background-image: url(../assets/images/nft-home-bg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    padding-bottom: 5em;
    /* padding-bottom: 25em; */
    /* background-size: 54%; */
    /* padding-bottom: 8em; */
}

.navbar-fixing {
    position: sticky !important;
    background-color: #000000 !important;
    width: 100% !important;
    z-index: 9999 !important;
    top: 0 !important;
}

.certified-modal .modal-content {
    /* border: 1px solid #fff; */
    background-color: #000;
    margin-top: 5rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 6.46%, rgba(255, 255, 255, 0.06) 100%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.certified-body img {
    height: 25em;
    object-fit: contain;
    width: 100%;
}

.certificate-header {
    border-bottom: none !important;
    padding: 5px;
    justify-content: flex-end !important;
}

.certificate-header button {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50% !important;
}

.certificate-header button:hover {
    background: #000;
    border: 1px solid #fff !important;
}

.datatable-column .even-color .rdt_TableHeadRow .rdt_TableCol {
    border: 1px solid #fff !important;
    background-color: #000 !important;
}

.datatable-column .even-color .iqRVCa {
    color: #fff !important;
    white-space: normal !important;
    overflow: unset !important;
    text-overflow: unset !important;
    text-align: center !important;
}

.datatable-column .even-color .rdt_TableBody .cSWinx {
    border: 1px solid #fff !important;
    color: #fff !important;
    border-top: none !important;
}

.even-color .rdt_TableRow:nth-child(even) {
    background-color: #C1BEBE !important;
}

.even-color .rdt_TableRow:nth-child(odd) {
    background-color: #959595 !important;
}

.even-color .jcAYlN {
    justify-content: center !important;
}

.even-color .jcAYlN .evgXsR {
    display: none !important;
}

.even-color .jcAYlN .itRwQa {
    display: none !important;
}

.even-color .jcAYlN .djgcPf {
    display: none !important;
}

.even-color .rdt_Pagination {
    background-color: #000 !important;
}

.even-color .gXHBHe {
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%) !important;
    margin-top: 1rem !important;
    border-radius: 10px !important;
}

.data-heading {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 26px;
    font-weight: 700;
    padding: 1rem;
    background-color: #000;
    width: 85%;
    margin: 1rem auto;
}

.data-heading span {
    color: #fff;
}

.custom-pagination {
    text-align: center;
    margin-top: 2rem;
    background: #000 !important;
    display: flex;
    justify-content: space-between;
}

.custom-pagination button {
    padding: 4px 10px;
    border: none;
    color: #fff;
    background-color: transparent;
}

.custom-part {
    border-radius: 10px;
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%) !important;
    display: flex;
}

.custom-part button {
    padding: 4px 6px;
}

.fixed-length {
    width: 8.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff !important;
}

.custom-part button:hover {
    background-color: #efeff2;
    color: #000;
}

.previous-key:hover {
    border-radius: 10px 0 0 10px;
}

.next-key:hover {
    border-radius: 0px 10px 10px 0px;
}

.download-button {
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%) !important;
    border: none;
    border-radius: 10px;
    padding: 2px 8px;
}

.customButton:hover {
    color: #fff !important;
}

.metamask-accordion .accordion-button {
    font-size: 19px;
    padding: 1rem 0;
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%) !important;
    border: none !important;
    color: #fff;
    font-weight: 600;
}

.metamask-accordion .accordion-header {
    margin-bottom: 0 !important;
    background: #fff !important;
}

.metamask-accordion .accordion-item{
    background: #000 !important;
    /* border-bottom-left-radius: 10px !important; */
    /* border-bottom-right-radius: 10px !important; */
}

.metamask-accordion .accordion-collapse {
    background-color: #000;
    /* border-bottom-left-radius: 10px !important; */
    /* border-bottom-right-radius: 10px !important; */
}

.metamask-accordion{
    background: #000 !important;
    /* border-bottom-left-radius: 10px !important; */
    /* border-bottom-right-radius: 10px !important; */
}

.metamask-accordion .accordion {
    border-radius: 10px;
    background: #000 !important;
}

.metamask-accordion .accordion-body {
    background-color: #000 !important;
    /* border-bottom-left-radius: 10px !important; */
    /* border-bottom-right-radius: 10px !important; */
}

.metamask-form {
    padding: 1rem;
    background: linear-gradient(180deg, rgba(182, 182, 182, 0.452) 6.46%, rgba(255, 255, 255, 0.06) 100%) !important;
    backdrop-filter: blur(3px) !important;
    /* border-bottom-left-radius: 10px !important; */
    /* border-bottom-right-radius: 10px !important; */
}

.metamask-accordion .accordion-body label {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
}

.metamask-accordion .accordion-body input {
    margin-bottom: 10px;
    background-color: transparent !important;
    border: 1px solid #b4b5ba;
    color: #fff !important;
}

.metamask-accordion .accordion-body input::placeholder {
    font-size: 14px;
    color: #b4b5ba !important;

}

.metamask-accordion .form-control:focus {
    box-shadow: none !important;
}

.metamask-accordion .accordion-body input {
    margin-bottom: 10px;
}

.metamask-accordion .submit-metamask {
    text-align: center;
}

.metamask-accordion .submit-metamask button {
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%) !important;
    border: none !important;
    font-size: 14px !important;
    margin-top: 10px;
}



@media(max-width:767px) {
    .wallet-handler {
        width: 6em !important;
        position: absolute !important;
        right: 2.5em !important;
        top: 1em !important;
    }

    .wallet-handler .dropdown .dropdown-item {
        margin: 2px 6px !important;
        width: 41%;
        background: transparent !important;
        text-align: center !important;
        font-size: 13px !important;
    }

    .data-heading {
        font-size: 17px;
        width: 100%;
    }

    .walletPic {
        display: block !important;
        height: 1.7em !important;
    }

    .navbar-fixing .navbar-collapse {
        padding: 1rem 0;
    }

    .navbar-fixing .navbar-collapse .ml-auto {
        margin-left: 0 !important;
    }

    .profile-dropdown {
        margin-top: 1rem !important;
    }

    .logout-for-mobile img {
        width: 2em !important;
        height: 2em !important;
        margin-left: 10px !important;
    }

    .navbar-fixing {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}