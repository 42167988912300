.data-list-box .rdt_TableHeader {
    text-align: center;
    color: #fff;
    font-weight: 900 !important;
    background: #000;
    margin-bottom: 0.5rem;
}

.data-list-box .rdt_TableHeadRow {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 15px;
}

.data-list-box .cSWinx {
    border: 1px solid #fff !important;
    font-weight: 600 !important;
}

.data-list-box .rdt_TableBody .rdt_TableRow:nth-child(even) {
    background: #C1BEBE !important;
}

.data-list-box .rdt_TableBody .rdt_TableRow:nth-child(odd) {
    background: #959595 !important;
}

.data-list-box .rdt_TableBody .eucAyA:not(:last-of-type) {
    border-bottom-style: none !important;
}

.data-list-box .rdt_Pagination {
    background-color: #000 !important;
    border: 1px solid #fff;
    justify-content: center;
}

.data-list-box .evgXsR {
    color: #fff !important;
}

.data-list-box .itRwQa {
    color: #fff !important;
}

.data-list-box .ePTvvj {
    color: #fff !important;
}

.data-list-box .gXHBHe {
    /* display: none; */
    /* background-color: #959595 !important; */
}

.data-box-heading {
    color: #fff;
    background-color: #000;
    padding: 0.5rem;
    margin-bottom: 0.4rem;
}

.data-box-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.data-box-heading button {
    background: linear-gradient(90deg, #4984D7 0%, #8A4BCE 100%);
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;
    padding: 7px 25px;
    border-radius: 7px;
    text-decoration: none !important;
    border: none !important;
}

.data-list-box  #pagination-first-page{
    /* border:2px solid red !important; */
    background-color: #b4b5ba !important;
    padding: 0 !important;
    margin-right: 5px !important;
    border: 1px solid grey;
    height: 30px;
    width: 30px;
}
.data-list-box #pagination-previous-page {
    /* border:2px solid red !important; */
    background-color: #b4b5ba !important;
    padding: 0 !important;
    margin-right: 5px !important;
    border: 1px solid grey;
    height: 30px;
    width: 30px;
}

.data-list-box  #pagination-next-page{
    /* border:2px solid red !important; */
    background-color: #b4b5ba !important;
    padding: 0 !important;
    margin-right: 5px !important;
    border: 1px solid grey;
    height: 30px;
    width: 30px;
 }
.data-list-box  #pagination-last-page{
    /* border:2px solid red !important; */
    background-color: #b4b5ba !important;
    padding: 0 !important;
    margin-right: 5px !important;
    border: 1px solid grey;
    height: 30px;
    width: 30px;
}