.meta-mast-component {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: initial;
  background: linear-gradient(180deg,hsla(0,0%,100%,.21) 6.46%,hsla(0,0%,100%,.06));
  border-radius: 27px;
  margin-bottom: 5rem;
  margin-top: 2rem;
  padding: 1.5rem 2rem;
}

.extension-not-found {
  font-family: Google Sans, arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  border-bottom: solid 2px red;
  width: 30%;
  padding-left: 10px;
  margin-bottom: 5px !important;
}

.extension-not-found-error-svg {
  color: red !important;
  border: none !important;
}

.extension-not-found-error-msg {
  color: white !important;
  color: red !important;
  margin-left: 10px;
}

.extension-not-found-error-link {
  color: rgb(0, 0, 0);
  margin-left: 40px;
  font-size: 16px;
  margin-top: 0.4rem !important;
  text-decoration: none !important;
  font-weight: bold;
  /* border: solid 2px rebeccapurple; */
  display: inline;
}

.extension-not-found-error-link:hover {
  color: white;
}

.wallet-connect-button {
  color: #ffffff;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  font-family: Google Sans, arial, sans-serif;
  display: inline;
}

.wallet-not-connected {
  font-family: Google Sans, arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  width: 30%;
  padding-left: 10px;
  margin-bottom: 5px !important;
}

.wallet-not-connected-svg {
  color: orange !important;
  border: none !important;
}

.wallet-not-connected-msg {
  color: white !important;
  color: orange !important;
  margin-left: 10px;
}

.wallet-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: black;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.wallet-address {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  font-weight: 600;
}

.wallet-address-svg {
  font-size: 20px;
  margin-right: 5px;
  color: white;
}

.wallet-address-primary {
  font-size: 17px !important;
  margin-right: 6px;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.wallet-amount {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin-top: 0.5rem;
  font-weight: 600;
}

.wallet-amount-svg {
  font-size: 20px;
  margin-right: 5px;
  color: white;
}

.wallet-amount-primary {
  font-size: 17px !important;
  margin-right: 6px;
  color: white;
}

.wallet-address-secondary {
  color: #6baa0d;
}
.wallet-amount-secondary {
  color: #6baa0d;
}
.wallet-details {
  font-size: 25px;
  color: white;
}
