.headVideo span {
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    #4bc9f0 9.56%,
    #b8d9e0 43.9%,
    #13a0bf 86.27%,
    #00aed4 100%
  );
  /* box-shadow: 6px 12px 7px 0px rgba(71, 152, 170, 0.8); */
  backdrop-filter: blur(70.5px);
  color: #fff;
  padding: 10px 40px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.headVideos span {
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    #4bc9f0 9.56%,
    #b8d9e0 43.9%,
    #13a0bf 86.27%,
    #00aed4 100%
  );
  /* box-shadow: 6px 12px 7px 0px rgba(71, 152, 170, 0.8); */
  -webkit-backdrop-filter: blur(70.5px);
  backdrop-filter: blur(70.5px);
  color: #fff;
  padding: 10px 40px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.headVideo {
  text-align: center;
}
.headVideos {
  text-align: center;
}

.videoSection {
  padding: 3em 0;
}
.headVideo h5 {
  padding: 3em 0;
  color: #ffffff;
  font-size: 24px;
}
.videocard {
  border-radius: 17px;
  border: 4px solid #fff;
  background: #15647e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 25em;
  width: 100%;
  position: relative;
  margin-top: 1em;
}

.innerCard {
  border-radius: 17px;
  border: 9px solid #00516a;
  /* opacity: 0.48; */
  background: #3394b3;
  box-shadow: 7px 24px 7px 0px rgba(0, 0, 0, 0.25);
  height: 25em;
  width: 100%;
  position: absolute;
  left: 2em;
  top: 2em;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.BenefitSection {
  padding: 2em 0 2em;
  background-color: #3e4c51;
  margin: 5em 0 0;
}
.benefitCards {
  border-radius: 7px;
  background: #fff;
  background-blend-mode: screen;
  box-shadow: 0px 4px 4px 0px #63d5f9;
  width: 100%;
  text-align: center;
  padding: 6em 2em 1em;
  margin-bottom: 4em;
  height: 20em;
}

.benefitCards p {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding-top: 1em;
}
.benefitCard {
  padding: 6em 0 0;
}

.benefitCards img {
  width: 5em;
}
.topMargin {
  margin-top: 8em;
}

.note p {
  color: #ffffff;
}

.learning img {
  width: 100%;
}

.learning {
  padding: 6em 0;
}

.jobSection p {
  color: #ffffff;
  padding: 3em 0 0;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.jobSection {
  background: #3e4c51;
  padding: 4em 0;
}

.webButton .btn {
  border-radius: 6px;
  /* opacity: 0.66; */
  background: linear-gradient(
    270deg,
    rgba(21, 192, 247, 0.85) 20.18%,
    rgb(99 213 249 / 39%) 93.35%
  );
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  font-size: 18px;
}
.section-hero h1 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.webButton a {
  color: #fff;
}

.webButton {
  text-align: center;
  margin: 2em 0;
}
.communitySection {
  padding: 3em 0 3em;
  background: #4C4C4C;
}

.hiringBtn .btn {
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    #4bc9f0 9.56%,
    #b8d9e0 43.9%,
    #13a0bf 86.27%,
    #00aed4 100%
  );
  /* box-shadow: 6px 12px 7px 0px rgba(71, 152, 170, 0.8); */
  backdrop-filter: blur(70.5px);
  color: #fff;
  padding: 0.5em 2em;
  font-size: 20px;
}

.hiringBtn {
  text-align: center;
  padding: 5em 0 3em;
}

.partnerLogo {
  text-align: center;
}

.footContent p {
  color: #857878;
  font-size: 18px;
  padding-top: 1em;
}

.footer {
  background-color: #000000;
}

.footer .title {
  color: #ffffff;
  font-weight: 600;
}

.videocard:hover {
  background-color: #20a8dd94;
  border-radius: 6px;
  border-radius: 17px;
}
.jobDetail p {
  color: #000;
  padding-top: 3em;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.jobDetail {
  padding: 3em 0;
}

.jobHead h5 {
  border-radius: 0px 3px 3px 0px;
  opacity: 0.66;
  background: linear-gradient(270deg, #6145b0 0%, #a16cd6 74.24%);
  box-shadow: 1px 8px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  color: #fff;
  width: 35%;
  padding: 1em;
}

.jobCards {
  border-radius: 7px;
  background: #fff;
  background-blend-mode: screen;
  box-shadow: 0px 4px 9px 1px rgba(214, 50, 255, 0.89), 0px 4px 4px 0px #63d5f9;
  padding: 1em 0 0;
  margin: 2em 0;
}

.jobCards ul li {
  list-style: initial;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 0.5em;
}
.jobCards ul li span {
  color: #000;
  font-weight: 600;
  color: #a16cd6;
}
.jobCards ul {
  padding: 1em 3em;
  margin-bottom: 0 !important;
}

.mainModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  transform: translate(0%, 15%) !important;
}

.mainModal .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.jobcard img {
  width: 100%;
}
.jobcard .accordion-button {
  border: none !important;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: linear-gradient(270deg, #05799e 0%, #56a6c0 74.24%);
  box-shadow: 1px 8px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.5px);
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.jobcard .accordion-header {
  border-radius: 3px;
  /* opacity: 0.66; */
  background: linear-gradient(270deg, #05799e 0%, #63d5f9 74.24%) !important;
  /* box-shadow: 1px 8px 4px 0px rgba(0, 0, 0, 0.25); */
  /* backdrop-filter: blur(1.5px); */
  height: 2.4em;
  margin-bottom: 0;
}

.accordion {
  border-radius: 7px !important;
  background: #fff !important;
  background-blend-mode: screen !important;
  /* box-shadow: 1px 8px 4px 0px rgba(0, 0, 0, 0.25) !important; */
  margin-bottom: 0.8em;
  /* padding: 1em 0; */
}

.jobcard .accordion-body {
  padding: 1.5em !important;
  background: #202020;
  color: #fff;
}
.jobcard .accordion-body span {
  color: #4bc7ee !important;
  font-weight: bold;
}
.jobcard .accordion-body li {
  font-size: 14px;
  padding-bottom: 1em;
  font-weight: 500;
}

.headVideo a {
  text-decoration: none !important;
}

.footer .Know-more ul li a:hover {
  color: #4bc9f0 !important;
}

.headVideoss {
  text-align: center;
}

.headVideoss span {
  border-radius: 7px;
  background: linear-gradient(
    90deg,
    #00b6f0 3.69%,
    #b8d9e0 44.34%,
    #0298b9 94.49%
  );
  /* box-shadow: 6px 12px 7px 0px rgba(71, 152, 170, 0.8); */
  backdrop-filter: blur(70.5px);
  color: #fff;
  padding: 10px 40px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.note a {
  text-decoration: none !important;
}

.note:hover p {
  color: #4bc9f0 !important;
}

.headVideoss a {
  text-decoration: none;
}
